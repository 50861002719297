nav {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100%;
    /* justify-content: space-between; */
    justify-content: flex-end;
    padding: 10px 80px;
    box-shadow: 0px 4px 5px 1px rgba(0,0,0,0.08);

    /* border-bottom: 2px solid var(--cl-gray-2); */
    /* padding-top: 10px; */
    /* padding: 20px 80px; */

    /* background-color: var(--cl-light); */
}

.nav--boxshadow {
    height: 5px;
    width: 100%;
    /* background-color: aqua; */
    /* box-shadow: 0px 4px 6px -2px rgba(0,0,0,0.5); */
    z-index: 1;
}

/* .nav--logo {
    padding: 8px 12px;
    display: inline-block;
    font-size: 2em;
    text-decoration: none;
    color: var(--cl-primary);
    font-weight: 600;


} */

nav ul {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;

}

nav ul li {
    list-style: none;

}

.nav--link, nav ul li a, .nav--menu2 li a {
    display: inline-block;
    color: var(--cl-gray-4);
    margin-left: 15px;
    font-weight: 500;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 10px;
    list-style: none;
    transition: 0.5s;
    /* background-color: aquamarine; */
}

.nav--link:hover, nav ul li a:hover, .nav--menu2 li a:hover {
    cursor: pointer;
    background-color: var(--cl-hover);
}

.toggle {
    display: none;
}

.nav--menu2 {
    display: none;
}

@media all and (max-width: 991px) {
    nav {
        padding: 10px 30px;
        display: flex;
        width: 100%;
        margin: 0;
        align-items: flex-start;
        /* flex-direction: column; */
    }

    nav ul {
        display: none;
    }
    .toggle {
        display: inherit;
        position: relative;
        width: 40px;
        height: 40px;
        align-items: flex-start;
    }

    .nav--menu2 {
        width: 100%;
        display: inherit;
        flex-direction: column;
        align-items: flex-end;
    }

    .nav--menu2 li {
        list-style: none;
        margin-right: 20px;
        /* background-color:; */
    }

}