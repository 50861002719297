#contactSection {
    padding: 20px 0;
    height: 90vh;
    align-items: center;
    display: flex;
    /* flex-direction: column; */

}

label {
    font-size: 18px;
    margin: 5px 0;
}

input, textarea {
    padding: 5px;
    border-radius: 15px;
    /* background-color: var(--cl-background) !important; */
}

.formBtn {
    padding: 8px 50px;
    border-radius: 15px;
    background-color: var(--cl-highlight);
    color: var(--cl-gray1);
    /* font-size: 1.2em; */
    font-weight: 700;
    max-width: max-content;
    align-self: center;
}

.contact--Form {
    /* border: 2px solid var(--cl-accent); */
    padding: 40px 40px;
    border-radius: 20px;
    background-color: var(--cl-gray2);
    box-shadow: 10px 10px 20px rgba(0,0,0,0.1);
}
  



.footer{
    display: flex;
    align-items: start;
    justify-content: center;
    height: 10vh;
    /* background-color: thistle; */
}

.footer > h3 {
    font-weight: 300;
    font-size: 18px;
    line-height: 2;
    word-spacing: 3px;
}

.footer__social {

    display: flex;
    padding: 0;
    margin-left: 40px;
    /* justify-content: center; */
}

.footer__social li {
    list-style: none;
    margin-left: 20px;

    /* padding: 0 20px; */
    /* display: inline-block; */

}

.home-formSectionDouble {
    display: flex;
    justify-content: space-between;
}

.home-formSectionDouble > .home-formSection {
    width: 49%;
}

.home-formSection {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
}

.home-formSection label {
    margin: 10px 0;
    font-size: 1.2em;
    color: var(--cl-gray-3);
}

.home-formSection input, .home-formSection textarea {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid var(--cl-gray-3);
    margin-bottom: 10px;
}

#footer_copyright > p {
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }
    .footer__social {
        margin: 0 0;
    }
    .home-formSectionDouble {
        flex-direction: column;
    }
    .home-formSectionDouble > .home-formSection {
        width: 100%;
    }
    .contactButton {
        width: max-content;
        padding: 10px 20px;
        font-size: 1em;
    }
    .home-formSection label {
        font-size: 1em;
    }


}


