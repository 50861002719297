.proj {
    justify-content: center;
    /* padding: 120px 0; */
}
.project__card {
    background-color: var(--cl-gray2);
    text-decoration: none;
    color: var(--cl-gray4);
    
    width: 80%;
    min-height: 440px;
    padding: 2.5rem;
    margin-bottom: 2.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 1.5rem;
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0, .2); */
}

.project__card:hover {
    background-color: var(--cl-gray1);
    transform: scale(1.025);
    transition: 350ms transform ease-in-out;
    box-shadow: 0 2px 15px 0 rgba(0,0,0, .15);
    cursor: pointer;
    /* background: linear-gradient(
        var(--cl-gray1),
        var(--cl-gray3)
    ); */
}

.project__cardText {
    display: flex;
    flex-direction: column;
}

.project__cardText > p, .project__cardText > h2 {
    /* margin-left: 10px; */
    line-height: 1.5;
    padding-bottom: 20px;
}

.project__cardText h2 {
    color: var(--cl-gray-4);
}

.project__cardText > p {
    font-size: 18px;
}

#call-action:hover > .MuiSvgIcon-root {
   transform: translateX(5px);
   transition: 300ms transform ease-in-out;
}

.call-actionGroup {
    display: flex;
    align-items: center;
}

.project__cardImg {
    /* width: 30%; */

    /* background-color: thistle; */
    margin-left: 40px;
    width: 100%;
    height: auto;

}

.project__cardImg > img {
    /* width: 300px; */
    height: auto;
    object-fit: contain;

    display: block;
    width: 400px;
    /* width: 600px; */
    /* max-height: 300px; */
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 1015px) {
    .project__card {
        flex-direction: column;
        justify-content: space-between;
    }
    .project__cardText > p, .project__cardText > h2 {
        padding-bottom: 10px;
        font-size: 1em;
    }
    .project__cardImg {
        margin-top: 2rem;
        margin-left: 0;
        max-width: fit-content;
    }
    .project__cardImg img {
        max-width: 100%;
    }
    
   
}

@media screen and (max-width: 425px) {
    .project__card {
        padding: 1.5rem;
    
    }
    .project__cardImg {
        margin-top: 1.5rem;
    }
    .call-actionGroup {
        flex-direction: column;
        align-items: start;
    }
}
