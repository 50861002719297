body, html {

  margin: 0;
  padding: 0;
  background-color: var(--cl-bg);
  box-sizing: border-box;
  
}

html {
  box-sizing: border-box;
  display: block;
}

.app{
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
}

section {
  margin: 0 auto;
  margin-bottom: 100px;
  display: block;
  justify-content: center;

}   

:root {
  --cl-bg: #eff0f3; /*background color*/
  --cl-white: #fffffe; /*headline color*/
  --cl-darkp: #001e1d; /*black text color*/
  --cl-accent: #d9376e; /*accent color*/
  --cl-highlight: #ff8ba7;
  --cl-btn: #f77f00; /*button color*/

  --cl-gray1: #F4F4F2;
  --cl-gray2: #E8E8E8;
  --cl-gray3: #BBBFCA;
  --cl-gray4: #333333;
  --cl-hover: #E8E8E8;
  
}
