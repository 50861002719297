.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    justify-content: space-around;
    position: relative;
    /* padding: 120px 0; */

}

.hero__hi {
    font-weight: 400;
}

.hero__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;

    /* background-color: aquamarine; */
}

.hero__info > h2 {
    max-width: 700px;
    font-weight: 300;
    line-height: 1.5;
}

.hero__info > h2 > span {
    background-color: var(--cl-highlight);
}

.hero__info > img {
    max-width: 30%;
    min-width: 250px;
    height: auto;
    margin: 0 5%;
    /* margin-top: 15px; */
    border-radius: 150px;
}

.hero__connect > span {
    font-weight: 300;
    font-size: 22px;
}

.home-heroSci {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-heroSci li {
    list-style: none;
}

.home-heroSci li a {
    display: inline-block;
    margin: 5px 0;
    padding: 8px;
    background-color: var(--cl-gray3);
    border-radius: 10px;
    color: var(--cl-gray4);
    transition: 0.5s;
}

.home-heroSci li:hover a {
    background-color: var(--cl-highlight);
    color: var(--cl-gray-3);
}


@media all and (max-width: 760px) {
    .hero__info {
        flex-direction: column;
        padding: 10 0;
        /* background-color: yellow; */
    }
    .hero__info > h2, .hero__hi {
        font-size: 22px;
    }
    .hero__info > img {
        margin: 30px 0;
    }
    .home-heroSci {
        position: inherit;
        flex-direction: row;
        align-self: center;
        justify-self: center;
        margin: 30px 0;
    }

    .home-heroSci li {
        margin-right: 15px;
        scale: 0.8;
    }
}

@media all and (max-width: 425px) {
    .hero__info > h2, .hero__hi {
        font-size: 18px;
    }
    .hero__connect > span {
        font-size: 14px;
    }
    .hero__hi {
        margin-top: 150px;
    }
    
}

@media all and (max-width: 325px) {
    .hero__info {
        padding: 5px 0;
    }
    .hero__info > img {
        margin-top: 20px;
    }

    
}